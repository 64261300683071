.sc-message {
    padding: 0px 15px;
    width: 100%-15px;
    display: flex;
}

.sc-message.expand {
    padding-top: 10px;
}

.sc-message--content {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.sc-message--content.sent {
    justify-content: flex-end;
}

.sc-message--avatar {
    align-self: center;
    margin-right: 15px;
}

.sc-message--avatar.hidden {
    visibility: hidden;
}

.sc-message--content---body {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.sc-message--username {
    font-size: 80%;
    font-weight: bold;
}

.sc-message--text {
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    outline: none;
    max-width: calc(100% - 120px);
    transition: transform 100ms ease-in-out;
}

.sc-message--text:hover {
    transform: scale(1.05);
}

.sc-message--text h1,
h2,
h3,
h3,
p {
    margin: 2px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.sc-message--text p {
    font-weight: 300;
    font-size: 14px;
}

.sc-message--text a {
    /* color: white; */
    font-weight: 300;
    font-size: 14px;
}

.sc-message--text a:link {
    color: white;
}

.sc-message--text a:visited {
    color: #fff04e;
}

.sc-message--text a:hover {
    color: white;
    text-decoration: none;
}

.sc-message--text a:active {
    color: white;
}

.sc-message--text p {
    word-wrap: break-word;
}

.sc-message--emoji {
    cursor: pointer;
    outline: none;
    transition: transform 100ms ease-in-out;
}

.sc-message--emoji:hover {
    transform: scale(1.1);
}

.sc-message--date {
    margin-bottom: 5px;
    font-size: 70%;
}

.sc-message--content.sent .sc-message--text {
    color: white;
    background-color: #4e8cff;
    align-self: flex-end;
}

.sc-message--content.sent .sc-message--date {
    align-self: flex-end;
}

.sc-message--content.sent .sc-message--emoji {
    align-self: flex-end;
}

.sc-message--content.received .sc-message--text {
    color: #263238;
    background-color: #f4f7f9;
    margin-right: 40px;
    align-self: flex-start;
}

.sc-message--content.received .sc-message--date {
    align-self: flex-start;
}

@media (max-width: 450px) {
    .sc-message {
        width: 80%;
    }
}

.sc-message-list {
    height: 80%;
    overflow-y: auto;
    background-color: white;
    background-size: 100%;
    padding-top: 40px;
}

/* From Joseph Fusco at https://codepen.io/fusco/pen/XbpaYv */

.typing-indicator-container {
    margin-left: 55px;
    padding-bottom: 15px;
}

.typing-indicator {
    background-color: #f4f7f9;
    width: auto;
    border-radius: 50px;
    padding: 15px;
    display: table;
    position: relative;
}

.typing-indicator:before,
.typing-indicator:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #f4f7f9;
}

.typing-indicator:after {
    height: 10px;
    width: 10px;
    left: -10px;
    bottom: -10px;
}

.typing-indicator span {
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 1px;
    background-color: #b4b4b4;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
    1% {
        transform: scale(1.2);
    }
    20% {
        transform: scale(1.2);
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
    1% {
        transform: scale(1.2);
    }
    20% {
        transform: scale(1.2);
    }
}

.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}
.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  color: #858585;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color .1s ease-out;
}
.emoji-mart-anchor:hover,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px; left: 0;
  width: 100%; height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 9px;
  right: 16px;
  z-index: 2;
  padding: 0;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, .95);
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}
.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}
.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em;
}
.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px; right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}
.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: .75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: .125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) { transition-delay: 0s }
.emoji-mart-skin-swatch:nth-child(2) { transition-delay: .03s }
.emoji-mart-skin-swatch:nth-child(3) { transition-delay: .06s }
.emoji-mart-skin-swatch:nth-child(4) { transition-delay: .09s }
.emoji-mart-skin-swatch:nth-child(5) { transition-delay: .12s }
.emoji-mart-skin-swatch:nth-child(6) { transition-delay: .15s }

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%; left: 50%;
  width: 4px; height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: .125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: .75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 { background-color: #ffc93a }
.emoji-mart-skin-tone-2 { background-color: #fadcbc }
.emoji-mart-skin-tone-3 { background-color: #e0bb95 }
.emoji-mart-skin-tone-4 { background-color: #bf8f68 }
.emoji-mart-skin-tone-5 { background-color: #9b643d }
.emoji-mart-skin-tone-6 { background-color: #594539 }

.sc-emoji-picker {
    position: absolute;
    bottom: 50px;
    right: 0px;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
    background: white;
    border-radius: 10px;
    outline: none;
}

.sc-emoji-picker:after {
    content: '';
    width: 14px;
    height: 14px;
    background: white;
    position: absolute;
    bottom: -6px;
    right: 49px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.sc-user-input {
    min-height: 55px;
    margin: 0px;
    position: relative;
    bottom: 0;
    display: flex;
    background-color: #f4f7f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    flex-direction: row;
}

.sc-user-input--text {
    width: 300px;
    resize: none;
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    box-sizing: border-box;
    padding: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #565867;
    -webkit-font-smoothing: antialiased;
    max-height: 200px;
    overflow: scroll;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: flex-start;
}

.sc-user-input--text:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    color: rgba(86, 88, 103, 0.3);
    outline: none;
}

.sc-user-input.active {
    box-shadow: none;
    background-color: white;
    box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.sc-user-input--buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
}

.sc-user-input--button {
    padding-left: 5px;
    padding-right: 5px;
    width: 30px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sc-user-input--send-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    align-self: center;
    outline: none;
}

.sc-user-input--send-icon path {
    fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--send-icon:hover path {
    fill: rgba(86, 88, 103, 1);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
}

.sc-user-input--send-icon-wrapper:focus {
    outline: none;
}

.sc-user-input--emoji-icon-wrapper:focus {
    outline: none;
}

.sc-user-input--emoji-icon {
    height: 18px;
    cursor: pointer;
    align-self: center;
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle,
.sc-user-input--send-icon path,
.sc-user-input--send-icon circle {
    fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle,
.sc-user-input--send-icon-wrapper:focus .sc-user-input--send-icon path,
.sc-user-input--send-icon-wrapper:focus .sc-user-input--send-icon circle,
.sc-user-input--send-icon.active path,
.sc-user-input--send-icon.active circle,
.sc-user-input--send-icon:hover path,
.sc-user-input--send-icon:hover circle {
    fill: rgba(86, 88, 103, 1);
}

.sc-user-input--send-icon {
    height: 18px;
    cursor: pointer;
    align-self: center;
}

.sc-header {
    background: #4e8cff;
    min-height: 75px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: white;
    padding: 10px;
    position: relative;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.sc-header--img {
    align-self: center;
    height: 54px;
    width: 54px;
}

.sc-header--img img {
    height: 34px;
    width: 34px;
    align-self: center;
    border-radius: 50%;
    padding: 10px;
}

.sc-header--team-name {
    align-self: center;
    padding: 10px;
    flex: 1 1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 5px;
}

.sc-header--team-name.enabled:hover {
    cursor: pointer;
    background: #4882ed;
}

.sc-header--close-button {
    width: 40px;
    align-self: center;
    height: 40px;
    margin-right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
}

.sc-header--close-button:hover {
    background: #4882ed;
}

.sc-header--close-button img {
    width: 100%;
    height: 100%;
    padding: 13px;
    box-sizing: border-box;
}

@media (max-width: 450px) {
    .sc-header {
        border-radius: 0px;
    }
}

.sc-chat-window {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 25px;
    bottom: 100px;
    box-sizing: border-box;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.sc-chat-window.closed {
    opacity: 0;
    visibility: hidden;
    bottom: 90px;
}

@media (max-width: 450px) {
    .sc-chat-window {
        width: 100%;
        height: 100%;
        max-height: 100%;
        right: 0px;
        bottom: 0px;
        border-radius: 0px;
    }
    .sc-chat-window {
        transition: 0.1s ease-in-out;
    }
    .sc-chat-window.closed {
        bottom: 0px;
    }
}

.sc-launcher {
    width: 60px;
    height: 60px;
    background-color: #4e8cff;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    right: 25px;
    bottom: 25px;
    border-radius: 50%;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;
    outline: none;
}

.sc-launcher:before {
    content: '';
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 25px;
    bottom: 25px;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}

.sc-launcher .sc-closed-icon {
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    width: 60px;
    height: 60px;
}

.sc-launcher .sc-open-icon {
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
}

.sc-launcher.opened .sc-open-icon {
    transform: rotate(-90deg);
    opacity: 1;
}

.sc-launcher.opened .sc-closed-icon {
    transform: rotate(-90deg);
    opacity: 0;
}

.sc-launcher.opened:before {
    box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
    box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

.sc-new-messsages-count {
    position: absolute;
    top: -3px;
    left: 41px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: #ff4646;
    color: white;
    text-align: center;
    margin: auto;
    font-size: 12px;
    font-weight: 500;
}

